export default {
	appSettings: {
		name: 'Provincia de Misiones',
		shortName: 'App Elecciones',
		version: 'v1.1.3',
		poweredBy: 'Dirección Innovacón, Desarrollo Tecnológico y Robótica',
		state: {
			SAVING_PERIOD: 1000,
		},

		regexps: {
			EMAIL: '[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+',
			NUMBER: /^[0-9]+$/,
			ALPHANUMERIC: /^[a-zA-Z0-9]+$/,
			NAME_HUMAN: /^[a-zA-ZñÑáéíóú ]+$/,
			STRING:
				'[/^[A-Za-z àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð]+$/u]',
		},
		googleSiteKey: '6LfUSlwlAAAAALkTmaxRgXWuFYmQbN3gnpijSwcf',
	},
	apis: {
		generalUrl: 'https://electoral2021.misiones.gob.ar/api/v1',
		authApi: {
			URL: 'https://keycloak.solucionesdigitales1024.com.ar/realms/IntermediaryCloud/protocol/openid-connect',
			system_id: 2,
			CLIENT_ID: 'elecciones-intermediary-cloud',
			CLIENT_SECRET: 'DmoWUjHycJ73FbGEchprCxbL0yNGuiZb',
			APPLICATION_ID: 7,
			AUTHENTICATION_ENDPOINT: '/token',
			LOGOUT: '/logout',
			RESETPASSWORD_ENDPOINT: '/users/password',
			USERS_FILTERED_ENDPOINT: '/v2/users/filtered',
			USER_ENDPOINT: '/v2/users',
			USER_PROFILES_ENDPOINT: '/v2/profiles',
			USER_X_APPLICATION_X_PROFILE_ENDPOINT: '/v1/userxapplicationxprofiles',
			HEADERS: {
				Accept: 'application/json',
				'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
			},
		},
	},
};
