import jwt_decode from 'jwt-decode';
import moment from 'moment';

export const round2Fixed = value => {
	// Shift
	value = value.toString().split(',');

	if (value[1]?.length > 1) {
		value = value[0] + ',' + value[1].slice(0, 2);
	} else if (value[1]?.length === 1) {
		value = value[0] + ',' + value[1] + '0';
	} else if (value[1]?.length === 0) {
		value = value[0] + ',' + '00';
	}
	return value;
};
export const removeAllSpaces = value =>
	value && typeof value === 'string' ? value.replace(/ /g, '') : undefined;

export const removeAllDots = value =>
	value && typeof value === 'string' ? value.replace(/[.]/g, '') : undefined;

export const removeAllDashes = value =>
	value && typeof value === 'string' ? value.replace(/[-]/g, '') : undefined;

export const removeAllDownDashes = value =>
	value && typeof value === 'string' ? value.replace(/[_]/g, '') : undefined;
export const removeAllDashesTwo = value =>
	value && typeof value === 'string' ? value.replace(/[_]/g, '') : undefined;
export const getFirstRecord = records =>
	Array.isArray(records) && records?.length > 0 ? records[0] : undefined;

export const numberFormatArg = num => {
	return String(num).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1.');
};
export const removeLastChar = (text, char) => {
	if (text && char && typeof text === 'string' && typeof char === 'string') {
		return text.slice(0, text.lastIndexOf(char));
	}
};

export const decodeJwt = token => {
	if (token) {
		return jwt_decode(token);
	}
	return false;
};

export const checkIfIsAdmin = token => {
	if (token) {
		const roles = token?.resource_access[
			'elecciones-intermediary-cloud'
		]?.roles.map(item => {
			return item.toLowerCase();
		});
		const obj = Object.values(roles);

		let isGeneralFiscal = Object.values(roles).includes('policia');
		return isGeneralFiscal;
	}
	return false;
};
/* eslint-disable */
export const getFileName = (preceedingType, userTableSelected) => {
	return `table-${userTableSelected}_${moment().format(
		'YYYY-MM-DD HH:mm:ss'
	)}_${preceedingType}.png`;
};

export const convertBase64ToFile = function (image) {
	const byteString = atob(image.split(',')[1]);
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	for (let i = 0; i < byteString.length; i += 1) {
		ia[i] = byteString.charCodeAt(i);
	}
	const newBlob = new Blob([ab], {
		type: 'image/jpeg',
	});
	return newBlob;
};

export const userMapperHelper = payload => {
	if (payload) {
		const userData = {
			school: payload.school,
			phone: payload.phone,
			name: payload.given_name,
			lastName: payload.family_name,
			fullName: payload.name,
			dni: payload.preferred_username,
			email: payload.email,
			status: payload.status,
			locality: payload.locality,
			circuit: payload.circuit,
		};
		return userData;
	}
};
