import * as ACTIONS from './globalActions';
import { toast } from 'react-toastify';
import { getAccessToken } from '../login/loginReducer';

export const setItsOnline = online => {
	return async (dispatch, getState) => {
		const state = getState();
		dispatch(ACTIONS.itsOnline(online));
	};
};

export const trySetMainTable = table => {
	return async (dispatch, getState) => {
		const state = getState();
		const token = getAccessToken(state);
		if (token) {
			dispatch(ACTIONS.setMainTable(table));
			toast.info(`La mesa seleccionada es ${table}`, {
				hideProgressBar: true,
			});
		}
	};
};
