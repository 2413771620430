import { useSelector } from 'react-redux';
import * as ROUTES from '../constants';
import { getIsLoggedIn, getPermission } from '../redux/login/loginReducer';
import { routesAndComponents } from './_routesAndComponents';

const hasPermission = route => {
	let fiscalCanSee = [
		ROUTES.URI_HOME,
		ROUTES.LOAD_PRECEEDING,
		ROUTES.LIST_OF_PRECEEDING,
	];
	return fiscalCanSee.includes(route);
};

const showComponent = route => {
	let componenetAndIcons = {
		component: null,
		name: null,
		to: null,
		icon: null,
	};
	routesAndComponents.forEach(element => {
		if (route === element.to && hasPermission(route)) {
			componenetAndIcons.componenet = element.componenet;
			componenetAndIcons.name = element.name;
			componenetAndIcons.to = element.to;
			componenetAndIcons.icon = element.icon;
		}
	});

	return componenetAndIcons;
};

export const NavRoutes = () => {
	const isLoggedIn = useSelector(state => getIsLoggedIn(state));
	let arrayRoutes = [];
	let routes;

	if (isLoggedIn) {
		arrayRoutes.push(ROUTES.URI_HOME);
		arrayRoutes.push(ROUTES.LOAD_PRECEEDING);
		arrayRoutes.push(ROUTES.LIST_OF_PRECEEDING);

		routes = arrayRoutes.map(item => {
			let data = {};
			let componenetItems = showComponent(item);
			if (componenetItems.name !== null) {
				if (componenetItems.to) {
					data = {
						name: componenetItems.name,
						component: componenetItems.componenet,
						icon: componenetItems.icon,
						to: componenetItems.to,
					};
				} else {
					if (hasPermission(item)) {
						data = {
							name: componenetItems.name,
							show: hasPermission(item),
							component: componenetItems.componenet,
							icon: componenetItems.icon,
							to: componenetItems.to,
						};
					}
				}
			}

			return data;
		});
	}
	let routesToMap = [];
	if (routes) {
		for (let index = 0; index < routes.length; index++) {
			if (routes[index].name) {
				routesToMap.push(routes[index]);
			}
		}
	}
	return routesToMap;
};
