import axios from 'axios';

import { removeLastChar } from '../utils/utils';

import {
	buildDefaultOptions,
	buildDefaultOptionsWithData,
} from './globalServices';

export const httpGet = (uri, options) => axios.get(uri, options);

export const httpPost = (uri, params, options) =>
	axios.post(uri, params, options);

export const httpPut = (uri, params, options) =>
	axios.put(uri, params, options);

export const httpDelete = (uri, options) => axios.delete(uri, options);

export const httpPatch = (uri, params, options) =>
	axios.patch(uri, params, options);

export const handleHttpError = error => ({
	error,
	response: error || error.response || error.response.data,
});

export const buildURLQuery = params => {
	const esc = encodeURIComponent;
	let query;
	if (params) {
		query = Object.keys(params)
			.map(key => {
				const value = params[key];
				let dato = '';
				let url;
				if (Array.isArray(value)) {
					url = value.reduce((accumulator, item) => {
						return (accumulator += `${key}[]=${item}&`);
					}, '');
					url = removeLastChar(url, '&');
				} else if (value || value === 0) {
					dato = value;
					url = esc(key) + '=' + esc(dato);
				}
				return url;
			})
			.join('&');
	}
	return query || '';
};

// START Generic methods
export const requestGET = async (URI, api_token, params) => {
	const options = buildDefaultOptions(api_token);

	const queryString = buildURLQuery(params);

	const URL = `${URI}?${queryString}`;

	let response;

	try {
		response = await httpGet(URL, options);
	} catch (error) {
		response = error?.response || error;
	}

	return response;
};

export const requestPOST = async (URI, api_token, params) => {
	const config = buildDefaultOptions(api_token);
	const URL = `${URI}`;

	let response;
	try {
		response = await httpPost(URL, params, config);
	} catch (error) {
		response = error?.response || error;
	}

	return response;
};

export const requestPUT = async (URI, api_token, params) => {
	const config = buildDefaultOptions(api_token);
	const URL = `${URI}`;

	let response;
	try {
		response = await httpPut(URL, params, config);
	} catch (error) {
		response = error?.response || error;
	}

	return response;
};

export const requestDELETE = async (URI, api_token, params) => {
	const config = buildDefaultOptions(api_token);
	const URL = `${URI}`;

	let response;
	try {
		response = await httpDelete(URL, config);
	} catch (error) {
		response = error?.response || error;
	}

	return response;
};
// END Generic methods
