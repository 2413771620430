import * as ACTION_TYPES from './globalActionTypes';

export const itsOnline = response => ({
	type: ACTION_TYPES.ITS_ONLINE,
	payload: response,
});

export const setMainTable = table => ({
	type: ACTION_TYPES.MAIN_TABLE,
	payload: table,
});
