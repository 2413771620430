import config from '../../configs/config';

import * as ACTIONS from './loginActions';

import * as SERVICES from '../../services/loginServices';
import { toast } from 'react-toastify';
import { getAccessToken } from './loginReducer';

import { checkIfIsAdmin, decodeJwt } from '../../utils/utils';
import { setItsOnline } from '../global/globalActionCreator';

export const tryLogInAction = params => {
	return async dispatch => {
		dispatch(ACTIONS.loginRequest(params));

		let response = await SERVICES.postLogin(params);

		if (response.status === 200) {
			let decodeToken = decodeJwt(response.data.access_token);
			if (checkIfIsAdmin(decodeToken)) {
				dispatch(ACTIONS.clearLoginData());
				if (!decodeToken) {
					toast.error(
						`Ocurrio un problema, vuelva a intentarlo en unos minutos.`,
						{
							hideProgressBar: true,
						}
					);
					dispatch(ACTIONS.loginRequestFailure(response.error));
					return {
						status: 400,
					};
				}
				let userData = {
					...decodeToken,
					access_token: response.data.access_token,
					refresh_token: response.data.refresh_token,
				};

				dispatch(setItsOnline(navigator.onLine));
				dispatch(ACTIONS.loginRequestSuccess(userData));

				toast.success(`Bienvenido!! ${config.appSettings.version}`, {
					hideProgressBar: true,
				});
			} else {
				response.status = 500;
				toast.error(
					`El usuario tiene un rol incorrecto. Contacte un administrador.`,
					{
						hideProgressBar: true,
					}
				);
				dispatch(ACTIONS.loginRequestFailure(response.error));
			}
		} else {
			toast.error(`El usuario o la contraseña no existen`, {
				hideProgressBar: true,
			});
			dispatch(ACTIONS.loginRequestFailure(response.error));
		}
		return response;
	};
};

// try logout
export const tryLogout = () => {
	return async (dispatch, getState) => {
		const state = getState();
		const access_token = getAccessToken(state);

		let paramsToSend = {
			refresh_token: state.loginReducer.authentication.refresh_token,
			client_id: config.apis.authApi.CLIENT_ID,
			client_secret: config.apis.authApi.CLIENT_SECRET,
		};
		// let response = {status:200}
		dispatch(ACTIONS.logoutRequest());

		let response = await SERVICES.logout(access_token, paramsToSend);

		if (response.status === 204) {
			dispatch(ACTIONS.logout());
			toast.info(`Sesión cerrada.`, { hideProgressBar: true });
			window.location.href = '/';
		} else {
			dispatch(ACTIONS.resetPasswordRequestFailure());
		}
		return response;
	};
};
