import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn } from '../redux/login/loginReducer';
import Footer from './commons/footer';
import Sidebar from './commons/sidebar';
import * as ROUTES from '../constants';
import { useNavigate } from 'react-router-dom';
import { setItsOnline } from '../redux/global/globalActionCreator';
import { getItsOnline } from '../redux/global/globalReducer';

const Layout = props => {
	const dispatch = useDispatch();

	const [isReadyForInstall, setIsReadyForInstall] = useState(false);
	console.log('isReadyForInstall', isReadyForInstall);
	let navigate = useNavigate();
	useEffect(() => {
		window.addEventListener('beforeinstallprompt', event => {
			// Prevent the mini-infobar from appearing on mobile.
			/* event.preventDefault(); */
			console.log('👍', 'beforeinstallprompt', event);
			// Stash the event so it can be triggered later.
			window.deferredPrompt = event;
			// Remove the 'hidden' class from the install button container.
		});
	}, []);
	async function downloadApp() {
		console.log('👍', 'butInstall-clicked');
		const promptEvent = window.deferredPrompt;
		if (!promptEvent) {
			// The deferred prompt isn't available.
			console.log('oops, no prompt event guardado en window');
			return;
		}
		// Show the install prompt.
		promptEvent.prompt();
		// Log the result
		const result = await promptEvent.userChoice;
		console.log('👍', 'userChoice', result);
		// Reset the deferred prompt variable, since
		// prompt() can only be called once.
		window.deferredPrompt = null;
		// Hide the install button.
		setIsReadyForInstall(false);
	}

	const isLoggedIn = useSelector(state => getIsLoggedIn(state));

	useEffect(() => {
		if (!isLoggedIn) {
			navigate(ROUTES.URI_INIT);
		}
		dispatch(setItsOnline(navigator.onLine));
	}, []);

	return (
		<div
			className={`${
				isLoggedIn
					? 'mainDashboardContainer'
					: 'mainDashboardContainer mainDashboardContainer-loggedOut'
			}`}
		>
			{props.children}
			<Footer />
		</div>
	);
};

export default Layout;
