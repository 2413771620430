import * as ACTION_TYPES from './loginActionTypes';

// Login actions
export const loginRequest = params => ({
	type: ACTION_TYPES.LOGIN_REQUEST,
	payload: {
		params,
	},
});

export const loginRequestSuccess = response => ({
	type: ACTION_TYPES.LOGIN_REQUEST_SUCCESS,
	payload: response,
});

export const loginRequestFailure = error => ({
	type: ACTION_TYPES.LOGIN_REQUEST_FAILURE,
	payload: {
		error,
	},
});
export const clearLoginData = () => ({
	type: ACTION_TYPES.CLEAR_LOGIN_DATA,
});
// END Login actions

// Forgot password send email actions
export const forgotPasswordSendEmailRequest = email => ({
	type: ACTION_TYPES.FORGOT_PASSWORD_SEND_EMAIL_REQUEST,
	payload: {
		email,
	},
});
export const forgotPasswordSendEmailRequestResolve = () => ({
	type: ACTION_TYPES.FORGOT_PASSWORD_SEND_EMAIL_REQUEST_RESOLVE,
});
// END Forgot password send email actions

// Reset password actions
export const resetPasswordRequest = () => ({
	type: ACTION_TYPES.RESET_PASSWORD_REQUEST,
});
export const resetPasswordRequestSuccess = response => ({
	type: ACTION_TYPES.RESET_PASSWORD_REQUEST_SUCCESS,
	payload: response,
});
export const resetPasswordRequestFailure = error => ({
	type: ACTION_TYPES.RESET_PASSWORD_REQUEST_FAILURE,
	payload: {
		error,
	},
});
export const clearResetPassword = () => ({
	type: ACTION_TYPES.CLEAR_RESET_PASSWORD_DATA,
});
// END Reset password actions

// Logout actions
export const logout = () => ({
	type: ACTION_TYPES.LOGOUT,
});
export const logoutRequest = () => ({
	type: ACTION_TYPES.LOGOUT_REQUEST,
});
// END Logout actions
