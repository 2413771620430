export const LIST_OF_USERS_REQUEST = 'LIST_OF_USERS_REQUEST';
export const LIST_OF_USERS_REQUEST_SUCCESS = 'LIST_OF_USERS_REQUEST_SUCCESS';
export const LIST_OF_USERS_REQUEST_FAILURE = 'LIST_OF_USERS_REQUEST_FAILURE';
export const CLEAR_LIST_OF_USER_PARAMS = 'CLEAR_LIST_OF_USER_PARAMS';

export const POST_USERS_REQUEST = 'POST_USERS_REQUEST';
export const POST_USERS_REQUEST_SUCCESS = 'POST_USERS_REQUEST_SUCCESS';
export const POST_USERS_REQUEST_FAILURE = 'POST_USERS_REQUEST_FAILURE';

export const PUT_USERS_REQUEST = 'PUT_USERS_REQUEST';
export const PUT_USERS_REQUEST_SUCCESS = 'PUT_USERS_REQUEST_SUCCESS';
export const PUT_USERS_REQUEST_FAILURE = 'PUT_USERS_REQUEST_FAILURE';

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST';
export const DELETE_USERS_REQUEST_SUCCESS = 'DELETE_USERS_REQUEST_SUCCESS';
export const DELETE_USERS_REQUEST_FAILURE = 'DELETE_USERS_REQUEST_FAILURE';
