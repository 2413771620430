import React, { useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { tryLogInAction } from '../../redux/login/loginActionCreator';
import { getIsFetching, getIsLoggedIn } from '../../redux/login/loginReducer';
import Loading from '../commons/loading';
import * as ACTIONS from '../../redux/login/loginActions';
// validations
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import config from '../../configs/config';
import FormFieldError from '../commons/formFieldError';

import { Link, useNavigate } from 'react-router-dom';
import ReCAPTCHA from 'react-google-recaptcha';
const messageRequired = 'Este campo es requerido';
const formSchema = Yup.object().shape({
	username: Yup.string()
		.required(messageRequired)
		.test('Is Number?', 'Solo se permiten números.', value =>
			RegExp(config.appSettings.regexps.NUMBER).test(value)
		),
	password: Yup.string().required(messageRequired),
});

const LoginForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm({ mode: 'onTouched', resolver: yupResolver(formSchema) });

	const captchaRef = useRef();
	const onChangeCaptcha = tokenValue => {
		onSubmitWithFormValues();
	};

	const usuarioRequiredMsg = 'Debe ingresar su número de D.N.I.';
	const usuarioValidationObj = { required: usuarioRequiredMsg };

	const loginIsFetching = useSelector(state => getIsFetching(state));
	const isLoggedIn = useSelector(state => getIsLoggedIn(state));
	useEffect(() => {
		if (isLoggedIn) {
			navigate('/inicio');
		}
	}, [isLoggedIn]);
	const onSubmitWithFormValues = handleSubmit((...formSubmitParams) =>
		onSubmit(...formSubmitParams)
	);
	const onSubmit = (values, e) => {
		captchaRef.current.execute();
		const recaptchaValue = captchaRef.current.getValue();

		if (recaptchaValue) {
			dispatch(tryLogInAction(values)).then(response => {
				if (response.status === 200) {
					navigate('/inicio');
				}
			});
		}
	};

	return (
		<>
			{loginIsFetching ? <Loading /> : null}
			<div className='login--form--container boxShadow'>
				<form onSubmit={handleSubmit(onSubmit)}>
					<div className='login--form--container__input '>
						<input
							placeholder='D . N . I'
							type='number'
							name='username'
							{...register('username', usuarioValidationObj)}
							className='text-center login--form--container__input__input'
						/>
					</div>
					<div className='login--form--container__input '>
						<FormFieldError errors={errors.username} />
					</div>

					<div className='login--form--container__input '>
						<input
							placeholder='Contraseña'
							type='password'
							name='password'
							{...register('password', usuarioValidationObj)}
							className='text-center login--form--container__input__input'
						/>
					</div>
					<div className='login--form--container__input '>
						<FormFieldError errors={errors.password} />
					</div>

					<div className='login--form--container__button_container'>
						<button className='login--form--container__button'>
							<span className='login--form--container__button__text'>
								Ingresar
							</span>
						</button>
					</div>
				</form>
				<ReCAPTCHA
					ref={captchaRef}
					sitekey={config.appSettings.googleSiteKey}
					size='invisible'
					className='captcha-container'
					onChange={onChangeCaptcha}
				/>
			</div>
		</>
	);
};

export default LoginForm;
