import * as ACTION_TYPES from './preceedingActionTypes';

export const preceedingReducerInitialState = {
	listOfPreceeding: {
		hasError: false,
		isFetching: false,
		data: null,
		meta: null,
		error: undefined,
	},
	postPreceeding: {
		hasError: false,
		isFetching: false,
		data: null,
		meta: null,
		error: undefined,
	},
	storePostPreceeding: {
		data: [],
	},
};

export const preceedingsReducer = (
	state = preceedingReducerInitialState,
	action
) => {
	switch (action.type) {
		// Preceeding actions
		case ACTION_TYPES.LIST_OF_PRECEEDING_REQUEST: {
			const { params } = action.payload;
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.LIST_OF_PRECEEDING_REQUEST_SUCCESS: {
			const { data, meta } = action.payload;
			return {
				...state,
				listOfPreceeding: {
					...state.listOfPreceeding,
					isFetching: false,
					hasError: false,
					error: undefined,
					data: data,
					meta: {
						limit: action.payload.limit,
						page: action.payload.page,
						pages: action.payload.pages,
						count: action.payload.count,
					},
				},
			};
		}

		case ACTION_TYPES.LIST_OF_PRECEEDING_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listOfPreceeding: {
					...state.listOfPreceeding,
					isFetching: false,
					hasError: true,
					// error
				},
			};
		}
		case ACTION_TYPES.CLEAR_LIST_OF_PRECEEDING_PARAMS: {
			return {
				...preceedingReducerInitialState,
			};
		}

		// END Preceeding actions

		// Preceeding actions
		case ACTION_TYPES.POST_PRECEEDING_REQUEST: {
			return {
				...state,
				postPreceeding: {
					...state.postPreceeding,
					isFetching: true,
					hasError: false,
					// error
				},
			};
		}
		case ACTION_TYPES.POST_PRECEEDING_REQUEST_SUCCESS: {
			const { data, meta } = action.payload;
			return {
				...state,
				postPreceeding: {
					...state.postPreceeding,
					isFetching: false,
					hasError: false,
					error: undefined,
					data: data,
					meta: meta,
				},
			};
		}

		case ACTION_TYPES.POST_PRECEEDING_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postPreceeding: {
					...state.postPreceeding,
					isFetching: false,
					hasError: true,
					// error
				},
			};
		}

		case ACTION_TYPES.STORE_POST_PRECEEDING_OFFLINE: {
			return {
				...state,
				storePostPreceeding: {
					...state.storePostPreceeding,
					data: action.payload,
				},
			};
		}

		case ACTION_TYPES.CLEAR_STORE_POST_PRECEEDING_OFFLINE: {
			return {
				...preceedingReducerInitialState,
			};
		}

		default: {
			return state;
		}
	}
};

export default preceedingsReducer;

export const getPreceedingReducer = state => state.preceedingsReducer;

export const getListOfPreceedingsData = state =>
	getPreceedingReducer(state)?.listOfPreceeding?.data;
export const getListOfPreceedingsDataMetaData = state =>
	getPreceedingReducer(state)?.listOfPreceeding?.meta;
export const getListOfPreceedingsIsFetching = state =>
	getPreceedingReducer(state)?.listOfPreceeding?.isFetching;

export const getPostPreceedingsIsFetching = state =>
	getPreceedingReducer(state)?.postPreceeding?.isFetching;

export const getStorePostPreceedings = state =>
	getPreceedingReducer(state)?.storePostPreceeding;
