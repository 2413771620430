export const LIST_OF_PRECEEDING_REQUEST = 'LIST_OF_PRECEEDING_REQUEST';
export const LIST_OF_PRECEEDING_REQUEST_SUCCESS =
	'LIST_OF_PRECEEDING_REQUEST_SUCCESS';
export const LIST_OF_PRECEEDING_REQUEST_FAILURE =
	'LIST_OF_PRECEEDING_REQUEST_FAILURE';
export const CLEAR_LIST_OF_PRECEEDING_PARAMS = 'CLEAR_LOGIN_PARAMS';

export const POST_PRECEEDING_REQUEST = 'POST_PRECEEDING_REQUEST';
export const POST_PRECEEDING_REQUEST_SUCCESS =
	'POST_PRECEEDING_REQUEST_SUCCESS';
export const POST_PRECEEDING_REQUEST_FAILURE =
	'POST_PRECEEDING_REQUEST_FAILURE';

export const STORE_POST_PRECEEDING_OFFLINE = 'STORE_POST_PRECEEDING_OFFLINE';
export const CLEAR_STORE_POST_PRECEEDING_OFFLINE =
	'CLEAR_STORE_POST_PRECEEDING_OFFLINE';
