import React from 'react';

const Loading = () => {
	return (
		<>
			<div className='rootLoading'>
				<div className='containerLoading'>
					<div className='loaderLoading' />
				</div>
			</div>
		</>
	);
};

export default Loading;
