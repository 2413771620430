export const buildAuthorization = accessToken => `Bearer ${accessToken}`;

export const buildDefaultOptions = (accessToken, responseType = 'json') => {
	let returnValue = { responseType };

	// eslint-disable-next-line no-unused-expressions
	accessToken
		? (returnValue.headers = {
				Authorization: buildAuthorization(accessToken),
				'content-type': 'application/json',
		  })
		: undefined;

	return returnValue;
};

export const mapToHttpBody = data => {
	return Object.keys(data)
		.map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
		.join('&');
};
