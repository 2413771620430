import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
/* default user image */
import UserImage from '../../assets/imagen-misiones.webp';
import { tryLogout } from '../../redux/login/loginActionCreator';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoggedIn, getUserData } from '../../redux/login/loginReducer';
import { NavRoutes } from '../../utils/navRoutes';
import { getItsOnline, getMainTable } from '../../redux/global/globalReducer';

const Sidebar = props => {
	const dispatch = useDispatch();

	const { showMenu, onClickShowMenu } = props;
	const loggedUserData = useSelector(state => getUserData(state));
	const defaultTable = useSelector(state => getMainTable(state));

	const onClickLogout = () => {
		dispatch(tryLogout());
	};
	const isLoggedIn = useSelector(state => getIsLoggedIn(state));
	const routes = NavRoutes();
	const itsOnline = useSelector(state => getItsOnline(state));

	const [supportsPWA, setSupportsPWA] = useState(false);
	const [promptInstall, setPromptInstall] = useState(null);

	useEffect(() => {
		const handler = e => {
			e.preventDefault();
			setSupportsPWA(true);
			setPromptInstall(e);
		};
		window.addEventListener('beforeinstallprompt', handler);

		return () => window.removeEventListener('transitionend', handler);
	}, []);

	return (
		<>
			<div
				className={`sidebar-menu-container ${
					showMenu ? ' sidebar-menu-collalsed' : ''
				}`}
			>
				<div className='sidebar-menu-header'>
					<div className='sidebar-menu--title'> Elecciones 2023</div>
					<div
						className='sidebar-menu--button-icons'
						onClick={() => onClickShowMenu()}
					>
						<div className='sidebar-menu--button-icon--hamburger'></div>
						<div className='sidebar-menu--button-icon--hamburger'></div>
						<div className='sidebar-menu--button-icon--hamburger'></div>
					</div>
				</div>
				<div
					className={`${
						isLoggedIn
							? itsOnline
								? 'sidebar-menu--descriptions sidebar-menu--descriptions-loggedIn'
								: 'sidebar-menu--descriptions'
							: 'sidebar-menu--descriptions'
					}`}
				>
					<div className='sidebar-menu--logo'>
						<img
							className='sidebar--menu--logo-renovation'
							src={UserImage}
						></img>
					</div>
					{loggedUserData && (
						<div
							className='sidebar-menu--name'
							key={loggedUserData.fullName}
						>{` ${loggedUserData.fullName}`}</div>
					)}
				</div>
				<div className='sidebar-menu--items-container'>
					{routes &&
						routes.map((route, idx) => {
							if (route.name !== 'password') {
								return (
									<>
										<div className='sidebar-menu--item' key={idx}>
											<div className='sidebar-menu--item-description '>
												{' '}
												<Link
													className='sidebar-menu-link text-grey'
													to={route.to}
												>
													{route.icon}{' '}
													<span className='sidebar-menu-link--name'>
														{route.name}
													</span>
												</Link>
											</div>
										</div>
										<div className='sidebar-menu--separator' />
									</>
								);
							}
						})}

					<div className='sidebar-menu--separator' />
					<div className='sidebar-menu--item'>
						<div className='sidebar-menu--item-description'>
							{' '}
							<a
								className='sidebar-menu-link text-grey cursor-pointer'
								onClick={() => onClickLogout()}
							>
								<FontAwesomeIcon
									className='sidebar-menu-link--icon'
									icon={faSignOutAlt}
								/>
								<span className='sidebar-menu-link--name'> Cerrar </span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default Sidebar;
