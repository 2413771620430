// Routes
export const URI_INIT = '/';
export const URI_HOME = '/inicio';
export const LOAD_PRECEEDING = '/cargar-acta';
export const PASSWORD = '/password';
export const LIST_OF_PRECEEDING = '/lista-de-actas';
export const PROFILE = '/perfil';
export const LIST_OF_PARTICIPATIONS = '/lista-de-participacion';
export const LIST_OF_PARTICIPATIONS_DNI = '/lista-de-participacion-dni';
export const USERS = '/usuarios';
