import config from '../configs/config';

import { httpPost, handleHttpError } from './httpServices';
import {
	mapToHttpBody,
	buildDefaultOptions,
	buildAuthorization,
} from './globalServices';

const API_BASE_URI_V1 = `${config.apis.authApi.URL}`;
const AUTH_ENDPOINT = `${config.apis.authApi.AUTHENTICATION_ENDPOINT}`;
const LOGOUT = `${API_BASE_URI_V1}${config.apis.authApi.LOGOUT}`;

export const postLogin = async params => {
	const { username, password } = params;
	const AUTH_URL = `${API_BASE_URI_V1}${AUTH_ENDPOINT}`;
	const details = {
		grant_type: 'password',
		username,
		password,
		client_id: config.apis.authApi.CLIENT_ID,
		client_secret: config.apis.authApi.CLIENT_SECRET,
	};
	const body = mapToHttpBody(details);

	var request = {
		headers: config.apis.authApi.HEADERS,
	};

	try {
		let response = await httpPost(AUTH_URL, body, request);
		return response;
	} catch (error) {
		return handleHttpError(error);
	}
};

// delete permission to profile
export const logout = async (api_token, params) => {
	let response;
	const options = buildDefaultOptions(api_token);
	const config = {
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
			Authorization: buildAuthorization(api_token),
		},
	};

	const URL = `${LOGOUT}`;

	try {
		response = await httpPost(URL, params, config);
	} catch (error) {
		response = error?.response || error;
	}

	return response;
};
