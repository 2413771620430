import * as ACTION_TYPES from './userActionTypes';

export const userReducerInitialState = {
	listOfUsers: {
		hasError: false,
		isFetching: false,
		data: null,
		meta: null,
		error: undefined,
	},
	postUsers: {
		hasError: false,
		isFetching: false,
		data: null,
		error: undefined,
	},
	putUsers: {
		hasError: false,
		isFetching: false,
		data: null,
		error: undefined,
	},
	deleteUsers: {
		hasError: false,
		isFetching: false,
		data: null,
		error: undefined,
	},
};

export const userReducer = (state = userReducerInitialState, action) => {
	switch (action.type) {
		// Users actions
		case ACTION_TYPES.LIST_OF_USERS_REQUEST: {
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.LIST_OF_USERS_REQUEST_SUCCESS: {
			const { data, meta } = action.payload;
			return {
				...state,
				listOfUsers: {
					...state.listOfUsers,
					isFetching: false,
					hasError: false,
					error: undefined,
					data: data,
					meta: meta,
				},
			};
		}

		case ACTION_TYPES.LIST_OF_USERS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				listOfUsers: {
					...state.listOfUsers,
					isFetching: false,
					hasError: true,
					// error
				},
			};
		}
		case ACTION_TYPES.CLEAR_LIST_OF_USER_PARAMS: {
			return {
				...userReducerInitialState,
			};
		}

		// END User actions

		// Post Users actions
		case ACTION_TYPES.POST_USERS_REQUEST: {
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.POST_USERS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				postUsers: {
					...state.postUsers,
					isFetching: false,
					hasError: false,
					error: undefined,
					data: data,
				},
			};
		}

		case ACTION_TYPES.POST_USERS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				postUsers: {
					...state.postUsers,
					isFetching: false,
					hasError: true,
					// error
				},
			};
		}
		// END Post User actions

		// Put Users actions
		case ACTION_TYPES.PUT_USERS_REQUEST: {
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.PUT_USERS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				putUsers: {
					...state.putUsers,
					isFetching: false,
					hasError: false,
					error: undefined,
					data: data,
				},
			};
		}

		case ACTION_TYPES.PUT_USERS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				putUsers: {
					...state.putUsers,
					isFetching: false,
					hasError: true,
					// error
				},
			};
		}
		// END Put User actions

		// Delete Users actions
		case ACTION_TYPES.DELETE_USERS_REQUEST: {
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.DELETE_USERS_REQUEST_SUCCESS: {
			const { data } = action.payload;
			return {
				...state,
				deleteUsers: {
					...state.deleteUsers,
					isFetching: false,
					hasError: false,
					error: undefined,
					data: data,
				},
			};
		}

		case ACTION_TYPES.DELETE_USERS_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				deleteUsers: {
					...state.deleteUsers,
					isFetching: false,
					hasError: true,
					// error
				},
			};
		}
		// END Put User actions
		default: {
			return state;
		}
	}
};

export default userReducer;

export const getUserReducer = state => state.userReducer;

// get
export const getListOfUsersData = state =>
	getUserReducer(state)?.listOfUsers?.data;
export const getListOfUsersDataMetaData = state =>
	getUserReducer(state)?.listOfUsers?.meta;
export const getListOfUsersIsFetching = state =>
	getUserReducer(state)?.listOfUsers?.isFetching;
// post
export const getPostUsersData = state => getUserReducer(state)?.postUsers?.data;
export const getPostUsersIsFetching = state =>
	getUserReducer(state)?.postUsers?.isFetching;

// put
export const getPutUserData = state => getUserReducer(state)?.putUsers?.data;
export const getPutUserIsFetching = state =>
	getUserReducer(state)?.putUsers?.isFetching;

// Delete
export const getDeleteUserData = state =>
	getUserReducer(state)?.deleteUsers?.data;
export const getDeleteUserIsFetching = state =>
	getUserReducer(state)?.deleteUsers?.isFetching;
