import { userMapperHelper } from '../../utils/utils';
import * as ACTION_TYPES from './loginActionTypes';

export const loginReducerInitialState = {
	hasError: false,
	isFetching: false,
	isLoggedIn: false,
	authentication: {},
	userData: {},
	error: undefined,
};

export const loginReducer = (state = loginReducerInitialState, action) => {
	switch (action.type) {
		// Login actions
		case ACTION_TYPES.LOGIN_REQUEST: {
			const { params } = action.payload;
			return {
				...state,
				isFetching: true,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.LOGIN_REQUEST_SUCCESS: {
			const { payload } = action;
			return {
				...state,
				isFetching: false,
				hasError: false,
				error: undefined,
				isLoggedIn: true,
				authentication: payload,
				userData: userMapperHelper(payload),
			};
		}

		case ACTION_TYPES.LOGIN_REQUEST_FAILURE: {
			const { error } = action.payload;
			return {
				...state,
				isFetching: false,
				hasError: true,
				isLoggedIn: false,
				error,
			};
		}
		case ACTION_TYPES.CLEAR_LOGIN_DATA: {
			return {
				...loginReducerInitialState,
			};
		}

		// END Login actions
		case ACTION_TYPES.LOGOUT_REQUEST: {
			return {
				...state,
				isFetching: false,
				hasError: false,
				isLoggedIn: false,
				error: undefined,
			};
		}
		case ACTION_TYPES.LOGOUT: {
			return {
				...loginReducerInitialState,
			};
		}

		default: {
			return state;
		}
	}
};

export default loginReducer;

export const getLoginReducer = state => state.loginReducer;
export const getIsLoggedIn = state => getLoginReducer(state).isLoggedIn;
export const getIsFetching = state => getLoginReducer(state).isFetching;

export const getUserAuthentication = state => getLoginReducer(state);
export const getUserData = state => getLoginReducer(state).userData;

export const getAccessToken = state =>
	getUserAuthentication(state)?.authentication?.access_token;

export const getLoggedUserData = state =>
	getLoginReducer(state)?.authentication;
