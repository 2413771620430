import React from 'react';
import config from '../../configs/config';

const Footer = () => {
	return (
		<>
			<div className='footer-container footer-color'>
				<div className='footer--container-text'>
					<span className='text-white'>
						{config?.appSettings?.name} - {config?.appSettings?.version}
					</span>
				</div>
			</div>
		</>
	);
};

export default Footer;
