import React, { Suspense, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './css/custom.css';

import Layout from './components/Layout';
import Login from './pages/login';

import Sidebar from './components/commons/sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import { getIsLoggedIn } from './redux/login/loginReducer';
import { history } from './redux/store';
import { NavRoutes } from '../src/utils/navRoutes';
import Loading from './components/commons/loading';

const App = () => {
	const [showMenu, setShowMenu] = useState(true);

	const onClickShowMenu = () => {
		setShowMenu(!showMenu);
	};
	const isLoggedIn = useSelector(state => getIsLoggedIn(state));

	const routes = NavRoutes();
	return (
		<Suspense fallback={<Loading />}>
			<Router history={history}>
				<Layout>
					<ToastContainer />
					{isLoggedIn && (
						<Sidebar showMenu={showMenu} onClickShowMenu={onClickShowMenu} />
					)}

					<Routes>
						{routes &&
							routes.map((route, idx) => {
								return (
									route.component && (
										<Route
											key={idx}
											path={route.to}
											name={route.name}
											element={<route.component />}
										/>
									)
								);
							})}
						<Route exact path='/' element={<Login />} />
					</Routes>
				</Layout>
			</Router>
		</Suspense>
	);
};

export default App;
