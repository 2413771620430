import * as ACTION_TYPES from './globalActionTypes';

export const globalReducerInitialState = {
	online: {
		hasError: false,
		isFetching: false,
		data: null,
		error: undefined,
	},
	mainTable: null,
};

export const globalReducer = (state = globalReducerInitialState, action) => {
	switch (action.type) {
		case ACTION_TYPES.ITS_ONLINE: {
			return {
				...state,
				online: {
					...state.online,
					isFetching: false,
					hasError: false,
					error: undefined,
					itsOnline: action.payload,
				},
			};
		}
		case ACTION_TYPES.MAIN_TABLE: {
			return {
				...state,
				mainTable: action.payload,
			};
		}

		default: {
			return state;
		}
	}
};

export default globalReducer;

export const getItsOnlineReducer = state => state.globalReducer;

export const getItsOnline = state =>
	getItsOnlineReducer(state)?.online.itsOnline;

export const getMainTable = state => getItsOnlineReducer(state)?.mainTable;
