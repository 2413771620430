import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { loginReducer, loginReducerInitialState } from '../login/loginReducer';
import {
	preceedingsReducer,
	preceedingReducerInitialState,
} from '../preceeding/preceedingReducer';
import { userReducer, userReducerInitialState } from '../user/userReducer';
import {
	globalReducer,
	globalReducerInitialState,
} from '../global/globalReducer';
import { LOGOUT } from '../login/loginActionTypes';

const createRootReducer = history => {
	const appReducer = combineReducers({
		router: connectRouter(history),
		loginReducer,
		preceedingsReducer,
		userReducer,
		globalReducer,
	});

	return (state, action) => {
		if (action.type === LOGOUT) {
			state.loginReducer = loginReducerInitialState;
			state.preceedingsReducer = preceedingReducerInitialState;
			state.userReducer = userReducerInitialState;
			state.globalReducer = globalReducerInitialState;
		}
		return appReducer(state, action);
	};
};

export default createRootReducer;
