import React from 'react';

import PropTypes from 'prop-types';
const FormFieldError = ({ errors }) => {
	return (
		<>
			{errors ? (
				<span role='alert' style={{ color: 'red' }}>
					{errors?.message}
				</span>
			) : null}
		</>
	);
};

FormFieldError.propTypes = {
	errors: PropTypes.object,
};

export default FormFieldError;
