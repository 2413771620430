import React from 'react';
import * as ROUTES from '../constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	faUserCheck,
	faList,
	faHome,
	faFileUpload,
	faUser,
	faUserCog,
	faFingerprint,
} from '@fortawesome/free-solid-svg-icons';
const Home = React.lazy(() => import('../pages/'));
const ListOfPreceeding = React.lazy(() =>
	import('../components/preceeding/ListOfPreceeding')
);
const Users = React.lazy(() => import('../components/users'));

const loadPreceeding = React.lazy(() =>
	import('../components/preceeding/Preceeding')
);

export const routesAndComponents = [
	{
		name: 'Inicio',
		to: ROUTES.URI_HOME,
		componenet: Home,
		icon: <FontAwesomeIcon className='sidebar-menu-link--icon' icon={faHome} />,
	},
	{
		name: 'Lista de actas',
		to: ROUTES.LIST_OF_PRECEEDING,
		componenet: ListOfPreceeding,
		icon: <FontAwesomeIcon className='sidebar-menu-link--icon' icon={faList} />,
	},
	{
		name: 'Cargar acta',
		to: ROUTES.LOAD_PRECEEDING,
		componenet: loadPreceeding,
		icon: (
			<FontAwesomeIcon
				className='sidebar-menu-link--icon'
				icon={faFileUpload}
			/>
		),
	},
	{
		name: 'Usuarios',
		to: ROUTES.USERS,
		componenet: Users,
		icon: <FontAwesomeIcon className='sidebar-menu-link--icon' icon={faUser} />,
	},
];
